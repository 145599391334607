import {  Dispatch } from "redux";
import { Contract } from "src/models/contract";

export interface ContractsState {
    contracts: Contract[]
}

export const InitialContractsState : ContractsState = {
    contracts: [
        {name:process.env.REACT_APP_NAME_MONSTA_BRIDGE,address:process.env.REACT_APP_CONTRACT_MONSTA_BRIDGE},
        {name:process.env.REACT_APP_NAME_MINTER_XSTT,address:process.env.REACT_APP_CONTRACT_MINTER_XSTT},
        {name:process.env.REACT_APP_NAME_BURNER_XSTT,address:process.env.REACT_APP_CONTRACT_BURNER_XSTT},

        {name:process.env.REACT_APP_NAME_XIMEGG,address:process.env.REACT_APP_CONTRACT_XIMEGG},
        {name:process.env.REACT_APP_NAME_XMONI,address:process.env.REACT_APP_CONTRACT_XMONI},
        {name:process.env.REACT_APP_NAME_XSTT,address:process.env.REACT_APP_CONTRACT_XSTT},
        {name:process.env.REACT_APP_NAME_MONISTTEX,address:process.env.REACT_APP_CONTRACT_MONISTTEX},
        {name:process.env.REACT_APP_NAME_MONSTA_CORE,address:process.env.REACT_APP_CONTRACT_MONSTA_CORE},
        {name:process.env.REACT_APP_NAME_AUCTION_ADMIN,address:process.env.REACT_APP_CONTRACT_AUCTION_ADMIN},
        {name:process.env.REACT_APP_NAME_MINTER_BRIDGE,address:process.env.REACT_APP_CONTRACT_MINTER_BRIDGE},
        {name:process.env.REACT_APP_NAME_MASTER_CHEF,address:process.env.REACT_APP_CONTRACT_MASTER_CHEF},
        {name:process.env.REACT_APP_NAME_DEAD,address:process.env.REACT_APP_CONTRACT_DEAD},
        {name:process.env.REACT_APP_NAME_XSTT_BRIDGE,address:process.env.REACT_APP_CONTRACT_XSTT_BRIDGE,abi:"xSTTBridge"},
        {name:process.env.REACT_APP_NAME_XSTT_EXTRA_BRIDGE,address:process.env.REACT_APP_CONTRACT_XSTT_EXTRA_BRIDGE},
    
        {name:process.env.REACT_APP_NAME_LP_XMONI_STAKING,address:process.env.REACT_APP_CONTRACT_LP_XMONI_STAKING},
        {name:process.env.REACT_APP_NAME_LP_XSTT_COLLECTOR,address:process.env.REACT_APP_CONTRACT_LP_XSTT_COLLECTOR},
        
        {name:process.env.REACT_APP_NAME_XMONI_BRIDGE,address:process.env.REACT_APP_CONTRACT_XMONI_BRIDGE,abi:"xMONIBridge"}
    
    ]
}

export enum ContractsTypes {
    ADD_CONTRACT= "ADD_CONTRACT"
}

export interface AddContractsAction{
    type: ContractsTypes.ADD_CONTRACT,
    payload:any
}

export type ContractsActions = AddContractsAction

export const addContract = (contract:Contract)=>{
    return{
        type: ContractsTypes.ADD_CONTRACT,

    }
}

export const ContractsReducer = (state:ContractsState=InitialContractsState, action:ContractsActions) => {

    switch (action.type){
        case ContractsTypes.ADD_CONTRACT:{
            return {
                ...state,
                constacts:[...state.contracts, action.payload]
            }
        }
        
        default:
        return state
        
    }
   

}