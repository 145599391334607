import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { ERC721Token } from "src/models/erc721Token";
import { formatAmount, groupBy } from "src/utils/utils";
import moniSvg from '../../assets/images/moni.svg';

type ERC721Props = {
    erc721Tokens:ERC721Token[]
}


const AddressERC721TokensFragment = (props:ERC721Props) =>{

    const [sortedERC721Tokens, setSortedERC721Tokens] = useState(null)
    const [tokenMonstaAmount,setTokenMonstaAmount] = useState(0)

    /*useEffect(()=>{
        //console.log(props.erc721Tokens.length)
         if (props.erc721Tokens.length > 0){
            let group = groupBy(props.erc721Tokens,"contract","name")
            //console.log(JSON.stringify(group))
            setSortedERC721Tokens(group)
            setTokenMonstaAmount(group.Monsta.length)
        }
    },[props.erc721Tokens])*/

    
    const renderTokens = (tokens:any) =>{
            return(
            
                <Col  key={`Tokens`} xl={3} className="custom-card-light-alert p-2">
                    <h4 className="flex-row">
                        <img className='symbol-icon' src={moniSvg} alt="stt" />
                        {props.erc721Tokens.length}
                    </h4> 
                    {"Monsta"}
                   
                    
                </Col>
            )
    } 

    return(
        <React.Fragment>
            <Row className="gap-2">
                {renderTokens(sortedERC721Tokens)}
            </Row>
        </React.Fragment>
    )
}

export default AddressERC721TokensFragment