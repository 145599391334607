import { Redirect } from "react-router-dom";

//Dashboard
import Home from "../pages/Home";

//Authentication pages
import Login from "src/pages/Authentication/Login";
import Logout from "src/pages/Authentication/Logout";
import Register from "src/pages/Authentication/Register";
import userProfile from "src/pages/Authentication/user-profile";
import TransactionDetail from "src/pages/Transaction";
import AddresstDetail from "src/pages/Address";
import TransactionERC721Detail from "src/pages/Transaction/erc721index";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [

  {path:"/address/:id", component:AddresstDetail},

  {path:"/transaction/:id", component:TransactionDetail},

  {path:"/transaction-erc721/:id", component:TransactionERC721Detail},
  
  // User Profile
  { path: "/profile", component: userProfile },

  // Dashboard
  { path: "/dashboard", component: Home },

  // My wallt
  { path: "/my-wallet", component: Home },

  // Transactions
  { path: "/transactions", component: Home },

  // Exchange
  { path: "/exchange", component: Home },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/transactions" /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },
];

export { userRoutes, authRoutes };
