import {
    ApolloClient,
    InMemoryCache,
    HttpLink,
    DefaultOptions,
    ApolloLink,

  } from "@apollo/client";
  

  const defaultOptions: DefaultOptions = {
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  }

const linkERC20 = new HttpLink({
    uri:`${process.env.REACT_APP_GRAPHQL_ENDPOINT}/token`,
});

  
export const client = new ApolloClient({
        link:linkERC20,
        cache: new InMemoryCache(/* {
         dataIdFromObject(responseObject) {
            switch (responseObject.__typename) {
              case 'account':
                console.log(responseObject.id)
              return `account:${responseObject.id}:${getRandomInt(100,1000)}`;
              default: return defaultDataIdFromObject(responseObject);
            }
          },
          typePolicies:{
            account:{
              keyFields:["id","ERC20balances","ERC721tokens"]
            }
          }
        }*/),
        defaultOptions:defaultOptions
    });


  