import {gql} from "@apollo/client"

const specificTransactionsQuery = gql`
query specificTransactionQuery($id:ID!) {
    transaction(id:$id){
        id
        timestamp
        blockNumber
    }  
}
`;

export default specificTransactionsQuery