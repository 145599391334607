import { render } from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./store/index";
import {ApolloProvider} from "@apollo/client";
import {client} from "./graphql/config"


render(
  <Provider store={configureStore({})}>
    <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
     </ApolloProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
