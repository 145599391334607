import { Contract } from "src/models/contract";
import {timeAgo} from "../timeagoConfig"
import { toast } from 'react-toastify';

export const timeConverter= (timestamp:any) =>{
    var a = new Date(timestamp );
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    


    var phour = hour < 10 ? "0" + hour : hour;
    var pmin = min < 10 ? "0" + min : min;
    var psec = sec < 10 ? "0" + sec : sec;


    var time = date + ' ' + month + ' ' + year + ' ' + phour + ':' + pmin + ':' + psec + " +UTC";
    return time;
}

export const timeUTCConverter= (timestamp:any) =>{
    var tmLoc = new Date(timestamp * 1000);
    //The offset is in minutes -- convert it to ms
    return timeConverter(tmLoc.toUTCString().slice(0, -3))
}

export const timeAgoFormat = (date:any) =>{
    return ` ${timeAgo.format(new Date(date * 1000),'mini')} ago`
}

export const ellipsify = (text:any, first = 6, last = 4) => {
    return `${text.slice(0, first)}...${text.slice(-last)}`;
}

export const zeroXToMoni = (address:string) => {
    if (address != null && address != undefined && address != ""){
        let newAddress:string = address.replace("0x","monsta:");
        return newAddress.toLowerCase();
    }
    return
}

export const moniToZeroX = (address:string) =>{
    if (address != null && address != undefined && address != ""){
        let newAddress:string = address.replace("monsta:","0x");
        return newAddress.toLowerCase();
    }
    return
}

export const formatAmount = (amount:any, fractionDigits=2) => {
    return Intl.NumberFormat("en-US",{minimumFractionDigits:fractionDigits, maximumFractionDigits:6}).format(amount);
}

export const copyText = (val:any) =>{
    navigator.clipboard.writeText(val.trim())
    toast('Address copied to clipboard.');
}

export const searchContractName= (address:string, contracts:Contract[]):any => {
    let ret = undefined

    let map  = contracts.map((contract,index)=>{
        if(String(contract.address).localeCompare(address)==0 ){
            //console.log(`From: ${index} ${contract.name}`)
            ret=contract
            
        }
    })

    return ret

}

export const getRandomInt=(min:any, max:any)=> {
    return Math.floor(Math.random() * (max - min)) + min;
}

 export const groupBy = (objectArray:any, property:any, insideproperty:any) => {
    return objectArray.reduce(function (acc:any, obj:any) {
      var key = obj[property][insideproperty];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }