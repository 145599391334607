export const DashboardIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="15.71" viewBox="0 0 15.2 15.71">
        <path id="Mask" d="M8.751,15.71V7.064H15.2V14.67a1.04,1.04,0,0,1-1.04,1.04Zm-7.71,0A1.04,1.04,0,0,1,0,14.67V10.51H6.449v5.2ZM0,8.646V1.04A1.04,1.04,0,0,1,1.04,0H6.449V8.646ZM8.751,5.2V0H14.16A1.04,1.04,0,0,1,15.2,1.04V5.2Z" fill="#fff" />
    </svg>
);

export const MyWalletIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="15.6" viewBox="0 0 15.2 15.6">
        <path id="Combined_Shape" data-name="Combined Shape" d="M13.761,15.6H1.04A1.041,1.041,0,0,1,0,14.56V1.04A1.042,1.042,0,0,1,1.04,0H13.761A1.042,1.042,0,0,1,14.8,1.04V2.6H6.115A1.041,1.041,0,0,0,5.076,3.64V11.96A1.041,1.041,0,0,0,6.115,13H14.8V14.56A1.041,1.041,0,0,1,13.761,15.6Zm.4-4.16H8.078a1.041,1.041,0,0,1-1.04-1.04V5.2a1.042,1.042,0,0,1,1.04-1.04H14.16A1.042,1.042,0,0,1,15.2,5.2v5.2A1.041,1.041,0,0,1,14.16,11.44ZM9.6,6.46a.521.521,0,0,0-.52.52V8.628a.521.521,0,0,0,.52.52h1.466a.521.521,0,0,0,.52-.52V6.98a.521.521,0,0,0-.52-.52Z" fill="#fff" />
    </svg>
);

export const TransactionIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.405" height="15.751" viewBox="0 0 14.405 15.751">
        <path id="Combined_Shape" data-name="Combined Shape" d="M0,8.548a7.216,7.216,0,0,1,5.462-6.99L5.644.268A.312.312,0,0,1,5.953,0H8.465a.313.313,0,0,1,.31.269L8.952,1.56A7.2,7.2,0,1,1,0,8.548ZM6.242,4.568V9.527H8.163l-1.914,0h0l4.659,1.7a.52.52,0,0,0,.667-.311l.3-.827a.521.521,0,0,0-.311-.667l-3.4-1.235V4.568a.52.52,0,0,0-.52-.52h-.88A.52.52,0,0,0,6.242,4.568Z" fill="#fff" />
    </svg>
);

export const ExchangeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="13.68" viewBox="0 0 15.2 13.68">
        <path id="Path_15310" data-name="Path 15310" d="M.76,0H14.44a.76.76,0,0,1,.76.76V12.92a.76.76,0,0,1-.76.76H.76A.76.76,0,0,1,0,12.92V.76A.76.76,0,0,1,.76,0ZM7.6,4.56H4.56V6.08H11.4L7.6,2.28ZM3.8,7.6l3.8,3.8V9.12h3.04V7.6Z" fill="#fff" />
    </svg>
);

export const InfoIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.308" height="24.308" viewBox="0 0 24.308 24.308">
        <path id="Mask" d="M12.154,24.308A12.154,12.154,0,1,1,24.308,12.154,12.168,12.168,0,0,1,12.154,24.308ZM10.938,15.8v2.431H13.37V15.8Zm0-9.723V13.37H13.37V6.077Z" fill="#fff" />
    </svg>
)

export const HelpIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.308" height="24.308" viewBox="0 0 24.308 24.308">
        <path id="Mask" d="M12.154,24.308A12.154,12.154,0,1,1,24.308,12.154,12.168,12.168,0,0,1,12.154,24.308ZM10.938,15.8v2.431H13.37V15.8Zm0-9.723V13.37H13.37V6.077Z" fill="#fff" />
    </svg>

)