import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Spinner } from "reactstrap";

type errorProps ={
    description:string
}

const ErrorCustom = (props:errorProps) =>{

    const [t, i18n] = useTranslation();
    return(
             <div className="p-3 text-center">
            <Row>
            <Col lg={12} className="align-self-center">
                    {t("Generic Error")}{props.description}
                </Col>
            </Row>
            </div>
    )
}

export default ErrorCustom