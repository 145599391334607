import {gql} from "@apollo/client"

const transactionDetailQuery = gql`
query transactionDetailQuery($id:ID!) {
  erc20Transfers(
    first:20
    skip:0
    orderBy:id
    orderDirection:asc
    where: {transaction:$id}
    subgraphError:deny
    ) {
      contract {
        symbol
        name
      }
      from {
        id
      }
      to {
        id
      }
      timestamp
      value
      valueExact
      block{
        id
        hash
        number
        gasUsed
        gasLimit
        timestamp
        difficulty
        size
      }
      transaction{
        id
        blockNumber
        gasLimit
        gasPrice
        nonce
        input
        logIndex
        transactionLogIndex
        logType
      }
    }
}
`;

export default transactionDetailQuery