import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap"
import { RootState } from "src/store/reducers";
import { Prices } from "src/utils/getTokenPrice"
// import images
import logo from "../../assets/images/monstainfinite_32.webp";
import {setSTTPrice} from '../../store/prices/reducer';
import sttSvg from '../../assets/images/stt.svg';


const TokenPriceFragmentSTT = () =>{
    const dispatch = useDispatch();

    const { statePriceSTT } = useSelector((state: RootState) => ({
        statePriceSTT: state.PriceReducer.priceSTT
    }));
    
    const [localSTTPrice, setLocalSTTDirectPrice] =useState("0.00")

    useEffect(() =>{
        if(statePriceSTT === null){
            /*Prices.getTokenPrice(process.env.REACT_APP_CONTRACT_STT!, process.env.REACT_APP_GECKO_BSC_ID!, "usd")
            .then(response => {
                
                setLocalSTTDirectPrice(response)
                dispatch(setSTTPrice(response));
                //console.log(`Price update2 ${response}`)  
            })
            .catch(error => {
                console.log (`Error ${error}`)  
            })*/
            Prices.cmcGetTokenPrice(process.env.REACT_APP_CMC_STT_ID)
            .then(response => {
                
                setLocalSTTDirectPrice(response)
                dispatch(setSTTPrice(response));
                //console.log(`Price update CMC ${JSON.stringify(response)}`)  
            })
            .catch(error => {
                console.log (`Error ${error}`)  
            })
        }
        
       
    },[localSTTPrice])



    return (
        <React.Fragment>
            <div className="monsta-white-color d-flex mr-10">
                <div className={"col-lg-auto col-md-auto col-sm-auto"} style={{marginRight: '10px'}}>
                    <span className="logo-sm">
                        <img src={sttSvg} alt="stt" width="40" height="40"/>
                    </span>
                </div>
                <div> 
                    <div>
                        {`$${statePriceSTT != null ? statePriceSTT : localSTTPrice} USD`} <br/>
                        {process.env.REACT_APP_NAME_STT}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TokenPriceFragmentSTT;