import React, { useEffect, useState } from "react";
import {Row,Col} from "reactstrap"
import { useTranslation } from 'react-i18next';
import { useQuery } from "@apollo/client";
import SpinnerCustom from "../Common/spinnerCustom";
import ErrorCustom from "../Common/errorCustom";
import { Link } from "react-router-dom";
import { formatAmount, searchContractName, zeroXToMoni } from "src/utils/utils";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import CopyTextFragment from "./copyTextFragment";
import { timeAgoFormat, timeConverter, timeUTCConverter } from "src/utils/utils";
import transactionERC721DetailsQuery from "src/graphql/transactionERC721DetailsQuery";
import { ERC721Transfer } from "src/models/erc721Transfer";
import InputDataDecoder, { InputData } from 'ethereum-input-data-decoder'
import ERC721abi from '../../abi/ERC721.json'

type Props ={
    txData:any
}

const TransactionERC721DetailFragment = (props:Props) => {

    const {stateContracts} = useSelector((state:RootState) =>({
        stateContracts: state.ContractsReducer.contracts
      }))
    
    const [fullShowArray, setFullShowArray] = useState<any>();
    
    const [ t, i18n ] = useTranslation();

    //QUERY TO GET DETAILS FROM A TRANSACTION
    const { loading: detailLoad, error: detailError, data: detailData } = useQuery(
        transactionERC721DetailsQuery,
        {
            variables:{id:props.txData.transaction.id}
        }
    );
    const [erc721Transfers, setERC721Transfers] = useState<ERC721Transfer[]>([]);

    useEffect(() =>{
        if (detailData){
            //console.log(detailData)
            setERC721Transfers(detailData.erc721Transfers)

            //create boolean arrays
            let boolArray: boolean[]= Array(detailData.erc721Transfers.length).fill(false)
            setFullShowArray(boolArray)
        }
    },[detailData])

    const handleFullShowChange = (index:number) =>{
        //console.log(index)
        let vals = fullShowArray
        vals[index]= !fullShowArray[index]
        setFullShowArray(vals)
        //console.log(fullShowArray[index])
    } 

    const decodeERC721Input = (input:string):InputData => {
        //console.log(input)
        let decoder = new InputDataDecoder(ERC721abi as any)
        let result = decoder.decodeData(input)
        //console.log(result)
        return result
    }


    if (detailLoad) return (<SpinnerCustom/>);
    if (detailError) return (<ErrorCustom description={detailError.message}/>);

    const renderTransfers = () => {

        let transfers = erc721Transfers.map((transfer,index) =>{
            
            let fromAddress 
            if (transfer.from?.id != null){
                let searchFrom =searchContractName(transfer.from?.id, stateContracts);
                fromAddress= searchFrom != undefined ? searchFrom.name : zeroXToMoni(transfer.from?.id);
            }
            else{
                fromAddress = String(transfer.contract?.symbol).localeCompare("STT") == 0 ? "Minted": "Monsta Bridge";
            }

            let toAddress
            if (transfer.to?.id != null){
                let searchTo = searchContractName(transfer.to?.id,stateContracts);
                toAddress =  searchTo != undefined ? searchTo.name : zeroXToMoni(transfer.to?.id);
            }else{
                toAddress = String(transfer.contract?.symbol).localeCompare("STT") == 0 ? "Burned": "Monsta Bridge";
            }

            let displayLinkAndCopyFrom:boolean = transfer.from?.id != null ? true :false
            let displayLinkAndCopyTo:boolean = transfer.to?.id != null ? true :false


            let symbolReplace = String(transfer.contract?.symbol).localeCompare("STT") ==0 ?"xSTT": transfer.contract?.symbol

           /* let searchFrom =searchContractName(transfer.from?.id, stateContracts);
            let searchTo = searchContractName(transfer.to?.id,stateContracts);
           
            let fromAddress = searchFrom != undefined ? searchFrom: zeroXToMoni(transfer.from?.id);
            let toAddress= searchTo != undefined ? searchTo: zeroXToMoni(transfer.to?.id);
            
            */

            let decodedInput= decodeERC721Input(transfer.transaction?.input!)

            return(
                <Row key={"ContTx_" + index} className={"monsta_transactions_detail"}>
                    <Col >
                        <Row key={"hash_" + index} >
                            <Col xl={2} md={2} sm={3}>
                                {t("Transaction Hash")}
                            </Col>
                            <Col xl={10} md={10} sm={9}>
                                {props.txData.transaction.id}
                                <CopyTextFragment txt={props.txData.transaction.id} display={displayLinkAndCopyFrom}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={2} md={2} sm={3}>
                                {t("Block")}
                            </Col>
                            <Col xl={10} md={10} sm={9}>
                                {props.txData.transaction.blockNumber}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={2} md={2} sm={3}>
                                {t("Timestamp")}
                            </Col>
                            <Col xl={10} md={10} sm={9}>
                               {` ${timeAgoFormat(props.txData.transaction.timestamp)} (${timeUTCConverter(props.txData.transaction.timestamp)})`}
                                
                            </Col>
                        </Row>
                        <Row key={"from_" + index} >
                            <Col xl={2} md={2} sm={3}>
                                {t("From")}
                            </Col>
                            <Col xl={10} md={10} sm={9}>
                                {displayLinkAndCopyFrom ? (
                                <Link to={`/address/${zeroXToMoni(transfer.from?.id)}`} className="monsta-link"> {fromAddress} </Link>
                                ):(fromAddress)
                                    }
                                <CopyTextFragment txt={zeroXToMoni(transfer.from?.id)} display={displayLinkAndCopyFrom}/>
                            </Col>
                        </Row>
                        <Row key={"To_" + index}>
                            <Col xl={2} md={2} sm={3}>
                                {t("To")}
                            </Col>
                            <Col xl={10} md={10} sm={9}>
                                {displayLinkAndCopyTo ? (
                            <Link to={`/address/${zeroXToMoni(transfer.to?.id)}`} className="monsta-link"> {toAddress}</Link>
                            ):(toAddress)}
                            <CopyTextFragment txt={zeroXToMoni(transfer.to?.id)} display={displayLinkAndCopyTo} />
                            </Col>
                        </Row>
                        <Row key={"Identifier_" + index}>
                            <Col xl={2} md={2} sm={3}>
                                {t("Identifier")}
                            </Col>
                            <Col xl={10} md={10} sm={9}>
                                {`${transfer.token?.identifier}`}
                            </Col>
                        </Row>

                        <Row key={"Symbol_" + index}>
                            <Col xl={2} md={2} sm={3}>
                                {t("ERC721 Token")}
                            </Col>
                            <Col xl={10} md={10} sm={9}>
                                {`${transfer.contract?.name} (${symbolReplace})`}
                            </Col>
                        </Row>
                        
                        <Row key={"Sep_" + index}>
                            <Col xl={12}><hr/></Col>
                        </Row>
                        <Row>
                        <a className="btn-more" href="#!" onClick={()=> {handleFullShowChange(index)}}>
                                {fullShowArray[index] ? 'Click to see less' : 'Click to see more'} <i className={`fas ${fullShowArray[index] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                            </a>
                        </Row>

                        {fullShowArray[index] && (
                        <Row>
                            <Col>
                                <Row key={"GasLimit_" + index}>
                                    <Col xl={2} md={2} sm={3}>
                                        {t("Gas Limit")}
                                    </Col>
                                    <Col xl={10} md={10} sm={9} className="value-row">
                                        
                                        {transfer.transaction?.gasLimit != null ? transfer.transaction?.gasLimit : t("Non available")}
                                    </Col>
                                </Row>
                        
                                <Row key={"GasPrice_" + index}>
                                    <Col xl={2} md={2} sm={3}>
                                        {t("Gas Price")}
                                    </Col>
                                    <Col xl={10} md={10} sm={9} className="value-row">
                                        
                                        {transfer.transaction?.gasPrice != null ? transfer.transaction?.gasPrice : t("Non available")}
                                    </Col>
                                </Row>

                                <Row key={"Nonce_" + index}>
                                    <Col xl={2} md={2} sm={3}>
                                        {t("Nonce")}
                                    </Col>
                                    <Col xl={10} md={10} sm={9} className="value-row">
                                        
                                        {transfer.transaction?.nonce != null ? transfer.transaction?.nonce : t("Non available")}
                                    </Col>
                                </Row>

                                <Row key={"Input_" + index}>
                                    <Col xl={2} md={2} sm={3}>
                                        {t("Input")}
                                    </Col>
                                    <Col xl={10} md={10} sm={9} className="value-row">
                                        <div className="text-input-log">
                                            {transfer.transaction?.input}<br/><br/>
                                            {t("Decoded Info")}<br/>
                                            {t("Method") + decodedInput.method }<br/>
                                            {t("Types") + JSON.stringify(decodedInput.types)}<br/>
                                            {t("Inputs") + JSON.stringify(decodedInput.inputs)}<br/>
                                            {t("Names") + JSON.stringify(decodedInput.names)}
                                            
                                        </div>
                                    
                                    </Col>
                                </Row>

                                <Row key={"LogIndex_" + index}>
                                    <Col xl={2} md={2} sm={3}>
                                        {t("Log Index")}
                                    </Col>
                                    <Col xl={10} md={10} sm={9} className="value-row">
                                        
                                        {transfer.transaction?.logIndex != null ? transfer.transaction?.logIndex: t("Non available")}
                                    </Col>
                                </Row>

                                <Row key={"TransactionLogIndex_" + index}>
                                    <Col xl={2} md={2} sm={3}>
                                        {t("Transaction Log Index")}
                                    </Col>
                                    <Col xl={10} md={10} sm={9} className="value-row">
                                        
                                        {transfer.transaction?.transactionLogIndex != null ? transfer.transaction?.transactionLogIndex : t("Non available")}
                                    </Col>
                                </Row>

                                <Row key={"LogType_" + index}>
                                    <Col xl={2} md={2} sm={3}>
                                        {t("Log Type")}
                                    </Col>
                                    <Col xl={10} md={10} sm={9} className="value-row">
                                        
                                        {transfer.transaction?.logType != null ? transfer.transaction.logType : t("Non available")}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>)
                        }
                    </Col>
                </Row>
            )
        })

        return transfers

    }
 
  if (erc721Transfers.length>0){
        return(
            <React.Fragment>
                {
                   renderTransfers()
                }
            </React.Fragment>
        )
    }
    return(
        <React.Fragment>
            <Row>
                <Col>
                </Col>
            </Row>
        </React.Fragment>
    )

}

 
export default TransactionERC721DetailFragment;