import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { ERC20Balance } from "src/models/erc20Balance";
import { formatAmount } from "src/utils/utils";
import moniSvg from '../../assets/images/moni.png';
import sttSvg from '../../assets/images/stt.svg';

type ERC20BalancesProps = {
    erc20Balances:ERC20Balance[]
}


const AddressERC20BalancesFragment = (props:ERC20BalancesProps) =>{

    const renderBalances = (balances:ERC20Balance[]) =>{
        let r = balances.map ((balance, index)=>{

            let symbol= String(balance.contract?.symbol).localeCompare("STT") == 0 ? "xSTT" : balance.contract?.symbol;

            return(
            
                <Col key={`Balance_${index}`} xl={3} className="custom-card-light-alert p-2">
                    <h4 className="flex-row">
                        {symbol.toLowerCase() === 'xmoni' && <img src={moniSvg} alt="moni" />}
                        {symbol.toLowerCase() === 'xstt' && <img src={sttSvg} alt="stt" />}
                        {formatAmount(balance.value)} 
                    </h4>
                    {`${balance.contract?.name} (${symbol})`} 
                </Col>
            )
        })
        return r
    } 

    return(
        <React.Fragment>
            <Row className="gap-2">
                {renderBalances(props.erc20Balances)}
            </Row>
        </React.Fragment>
    )
}

export default AddressERC20BalancesFragment