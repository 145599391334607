import React, { useState } from "react";
import { Link } from "react-router-dom";

//import drawer
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

//import component
import RightSidebar from "../CommonForBoth/RightSidebar";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import LightDark from "../CommonForBoth/Menus/LightDark";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";

//import images
import logoSvg from "../../assets/images/monsta-logo.png";
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

//redux
import { useSelector } from "react-redux";
import SearchFragment from "../Monsta/searchFragment";

const Header = (props: any) => {
  const { layoutMode } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  const [search, setsearch] = useState<boolean>(false);
  const [socialDrp, setsocialDrp] = useState<boolean>(false);
  const [isClick, setClick] = useState<boolean>(true);
  const [position, setPosition] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);

  /**
   * Rightsidebar drawer
   */
  const toggleTopDrawer = () => {
    setPosition("right");
    setOpen(!open);
  };

  const onDrawerClose = () => {
    setOpen(false);
  };

  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    const isClick = !body.classList.contains("sidebar-enable");
    const isMobile = document.body.clientWidth < 992;
    if (isClick === true) {
      body.classList.add("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", isMobile ? "sm" : "lg");
    } else {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", isMobile ? "lg" : "sm");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header justify-content-between">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/transactions" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="24" />{" "}
                  <span className="logo-txt">Monsta Scan</span>
                </span>
              </Link>

              <Link to="/transactions" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="30" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="24" />{" "}
                  <span className="logo-txt">Monsta Scan</span>
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

            
          </div>
          <div className="d-flex justify-content-between flex-fill">
            <div className="mobile" />
            <div className="d-flex col-lg-4 desktop" >
              <SearchFragment />
            </div>
            <div className="d-flex flex-end">
              {/*
              <LanguageDropdown/>
              <NotificationDropdown />

              <LightDark
                layoutMode={layoutMode}
                onChangeLayoutMode={props.onChangeLayoutMode}
              />

              

              <div
                onClick={() => {
                  toggleTopDrawer();
                }}
                className="dropdown d-inline-block"
              >
                <button
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle"
                >
                  <Icon name="settings" className="icon-lg" />
                </button>
              </div>
              <ProfileMenu />
             
             */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="d-flex col-lg-4 mobile" >
            <SearchFragment />
          </div>
        </div>
      </header>
      <ReactDrawer open={open} position={position}>
        <RightSidebar
          onClose={onDrawerClose}
          onChangeLayoutMode={props.onChangeLayoutMode}
        />
      </ReactDrawer>
    </React.Fragment>
  );
};

export default Header;
