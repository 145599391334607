import React, { useState } from "react";

type Props = {
    total: number;
    page: number;
    onChangePage: (page: number) => void;
}

const MonstaPagination: React.FC<Props> = ({ total, page, onChangePage }) => {
    return (
        <span className="custom-pagination">
            <span
                onClick={() => onChangePage(1)}
                className={page===1?"disabled":""}
            >
                First
            </span>
            <span
                onClick={()=>onChangePage(page > 1 ? page-1 : 1)}
                className={page===1?"disabled":""}
            >
                <i className="fas fa-angle-left"></i>
            </span>
            <span>{page} / {total}</span>
            <span
                onClick={()=>onChangePage(page < total ? page+1 : total)}
                className={page===total?"disabled":""}
            >
                <i className="fas fa-angle-right"></i>
            </span>
            <span
                onClick={() => onChangePage(total)}
                className={page===total?"disabled":""}
            >
                Last
            </span>
        </span>
    )
};

export default MonstaPagination;