import {gql} from "@apollo/client"

const blocksQuery = gql`
query blocksQuery($first:Int!, $skip:Int!) {
    blocks(
      skip:$skip
      first:$first
      orderDirection:desc
      orderBy:timestamp
      subgraphError:deny
    ){
      id
      hash
      number
      gasUsed
      gasLimit
      timestamp
      difficulty
      size
    }
  }`;

  export default blocksQuery