import React, { useEffect, useState } from "react";
import {Row,Col, CardHeader, CardTitle, CardBody, Nav, NavItem, NavLink, Card, TabContent, TabPane, CardText, Table, Alert} from "reactstrap"
import MetaTags from "react-meta-tags";
import UnknownProblem from "src/components/Common/unknownProblem";
import { useParams } from "react-router";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import addressERC20TransactionsQuery from "src/graphql/addressERC20TransactionsQuery";
import addressERC721TransactionsQuery from "src/graphql/addressERC721TransactionsQuery";
import { gql, useQuery } from "@apollo/client";
import SpinnerCustom from "src/components/Common/spinnerCustom";
import ErrorCustom from "src/components/Common/errorCustom";
import { Link } from "react-router-dom";
import AddressERC20BalancesFragment from "src/components/Monsta/addressERC20BalancesFragments";
import { moniToZeroX, searchContractName } from "src/utils/utils";
import CopyTextFragment from "src/components/Monsta/copyTextFragment";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import { Contract } from "src/models/contract";
import TransactionsTabsWrapperFragment from "src/components/Monsta/transactionsTabsWrapperFragment";
import AddressERC721TokensFragment from "src/components/Monsta/addressERC721TokensFragment";
import { TransferTypes } from "src/models/transferTypes";
import addressERC721TokensQuery from "src/graphql/addressERC721TokensQuery";
import { ERC721Token } from "src/models/erc721Token";



type AccountProps ={
    id:string
}


const AddressDetail =() =>{

    const base= parseInt(process.env.REACT_APP_BIG_QUERY_TRANSACTIONS!)
    const {stateContracts} = useSelector((state:RootState) =>({
        stateContracts: state.ContractsReducer.contracts
    }))

    const [ t, i18n ] = useTranslation();
    const { id }= useParams<AccountProps>();    
    const [transferTab, setTransferTab]= useState("1")


    
    //QUERY TO GET ERC20 TRANSACTIONS
    const { loading: addressERC20Load, error: addressERC20Error, data: addressERC20Data } = useQuery(
        addressERC20TransactionsQuery,
        {
            variables:{id:moniToZeroX(id),first:parseInt(process.env.REACT_APP_LAST_TRANSFERS!)}
        }
    );

    //QUERY TO GET ERC721 TRANSACTIONS
    const{ loading:addressERC721Load, error: addressERC721Error,data:addressERC721Data }= useQuery(
        addressERC721TransactionsQuery,
        {
            variables:{id:moniToZeroX(id),first:parseInt(process.env.REACT_APP_LAST_TRANSFERS!)}
        }

    )

    //QUERY TO GET NUMBER OF ERC721 TOKENS
    
    const {loading: ERC721TokensLoad, error:addressERC721TokensError,data:addressERC721TokensData, fetchMore:fmore} =useQuery(
        addressERC721TokensQuery,
        {variables:{id:moniToZeroX(id),first:base, skip:0}},

    )
    
    const onFetchMore = () => {
    
        fmore({
            variables:{id:moniToZeroX(id),first:base,skip:addressERC721TokensData.account.ERC721tokens.length},
            updateQuery:(previousResult:any, {fetchMoreResult}:any) =>{
                //console.log(`fetched: ${fetchMoreResult.account.ERC721tokens.length}`)
               if (!fetchMoreResult) {
                    return previousResult;
                }
                let prev = previousResult.account.ERC721tokens
                let more= fetchMoreResult.account.ERC721tokens

                fetchMoreResult.account.ERC721tokens = [...prev,...more]

                return{
                    ...previousResult,
                    ...fetchMoreResult
                }
            }
        });
      }

    
      

    useEffect(()=>{
       
        if (addressERC721TokensData && addressERC721TokensData.account != null){
            
            if (addressERC721TokensData.account.ERC721tokens.length > 0){
                //console.log(`length ${addressERC721TokensData.account.ERC721tokens.length}`)
                onFetchMore()           
            }

        }
    },[addressERC721TokensData] )
    

    const toggleTransferTab = (tab:any) =>{
        if(transferTab!== tab){
            setTransferTab(tab)
        }
    }

   

    const replaceTitleAddress = (address:any, contracts: Contract[]) =>{

        let addressName= searchContractName(moniToZeroX(address)!, contracts)

        if ( addressName != undefined){
            return(
            <>
                <h5><span className="monsta-yellow-color"> {addressName.name }</span></h5>
                <h6> {t("Address")} {address} <CopyTextFragment txt={address} display={true}/></h6>
            </>
            )
        }else{

            return(
                <h5>
                    {t("Address")}
                    <span className="address-container">
                        <span className="monsta-yellow-color"> {address}</span>
                        <CopyTextFragment txt={address} display={true}/>
                    </span>
                </h5>
            )

        }
    }
    
    //console.log( "Account Data:" + JSON.stringify(accountError))
    if (addressERC20Load) return (<SpinnerCustom/>);
    if (addressERC20Error) return (<ErrorCustom description={addressERC20Error.message}/>);



    if (addressERC20Data.account != null ){
        
        //console.log("TODO" + JSON.stringify(account))
    return(
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Address Details</title>
                </MetaTags>
                <div className="container-fluid">
                <Row className={"mb-3 mt-3 p-1"}>
                    <Col xl={12} md={12} sm={12}>
                        
                        {replaceTitleAddress(id.toLocaleLowerCase(),stateContracts)}

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                           
                            { <CardBody><h6>{t("ERC20 Tokens")} </h6><AddressERC20BalancesFragment erc20Balances={addressERC20Data.account.ERC20balances}/></CardBody>}
                            {addressERC721TokensData && addressERC721TokensData.account != null ? 
                            (<CardBody ><h6>{t("ERC721 Tokens")} </h6><AddressERC721TokensFragment erc721Tokens={addressERC721TokensData.account.ERC721tokens}/></CardBody>)
                            :null}
                        </Card>
                    </Col>
                </Row>
                <Row className={"mt-3 p-2"}>
                    <Col>
                        <div className="align-items-center d-flex">
                            <div className="flex-grow-1">
                                <Nav tabs>
                                <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                    active: transferTab === "1",
                                    })}
                                    onClick={() => {
                                    toggleTransferTab("1");
                                    }}
                                >

                                <span className="d-sm-block">ERC20 Transfers</span>
                                </NavLink>
                                </NavItem>

                                {
                                /**VALIDATE ADDRESS HAS ERC721 ACCOUNT */
                                addressERC721Data  && addressERC721Data.account != null ? (
                                <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                    active: transferTab === "2",
                                    })}
                                    onClick={() => {
                                        toggleTransferTab("2");
                                    }}
                                >
            
                                    <span className="d-sm-block">ERC721 Transfers</span>
                                </NavLink>
                                </NavItem>
                                ):null}
                            
                                </Nav>
                            </div>
                        </div>
                       
                
                    </Col>
                </Row>
                <Row>
                    <Col>
                    { transferTab==="1" && (
                        <TransactionsTabsWrapperFragment type={TransferTypes.ERC20} outgoingTransfers={addressERC20Data.account.ERC20transferFromEvent} incomingTransfers={addressERC20Data.account.ERC20transferToEvent}  id={id}/>
                    
                    )}
                    {transferTab==="2" && addressERC721Data && addressERC721Data.account != null ? (
                        <TransactionsTabsWrapperFragment type={TransferTypes.ERC721} outgoingTransfers={addressERC721Data.account.ERC721transferFromEvent} incomingTransfers={addressERC721Data.account.ERC721transferToEvent}  id={id}/>
                    
                    ):null
                    }
                    </Col>
                </Row>
                </div>
            </div>
        </React.Fragment>
    )
    }
    return(
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Address Details</title>
                </MetaTags>
                <div className="container-fluid">
                <Row className={"mb-3 mt-3 p-1"}>
                    <Col xl={12} md={12} sm={12}>   
                    <h5>{t("Address")} <span className="monsta-yellow-color"> {id}</span></h5>
                    </Col>
                </Row>
                <Row className={"mt-2 p-1"}>
                    <Col xl={12} md={12} sm={12}>
                        <Alert color="warning">
                           {t("There are no matching entries")}
                        </Alert>
                    </Col>
                    
                </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddressDetail

