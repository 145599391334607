import {gql} from "@apollo/client"

const addressERC20TransactionsQuery = gql`
   
query addressERC20TransactionsQuery($id:ID!, $first:Int!){
  account(id:$id){
      id
      ERC20balances(
          skip:0
          first:$first
        ){
          contract{
            symbol
            name
          }
          value
          valueExact
        }
        
        ERC20transferToEvent(
          skip:0
          first:$first
          orderBy:timestamp
          orderDirection:desc
        ) {
          id
          timestamp
          valueExact
          value
          transaction{
            id
          }
          contract {
            name
            symbol
          }
          to {
            id
          }
          from {
            id
          }
        }
        ERC20transferFromEvent(
          skip:0
          first:$first
          orderBy:timestamp
          orderDirection:desc
        ){
          id
          timestamp
          valueExact
          value
          transaction{
            id
          }
          contract {
            name
            symbol
          }
          to {
            id
          }
          from {
            id
          }
        }
  }
}
`;

export default addressERC20TransactionsQuery