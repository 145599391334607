import {gql} from "@apollo/client"

const addressERC721TokensQuery= gql`
   
query addressERC721TransactionsQuery($id:ID!, $first:Int!, $skip:Int!){
    account(id:$id){
        id
        ERC721tokens(
         skip:$skip
         first:$first
       ){
         id
         contract{
           name
           symbol
         }
       }
    
  }
}
`;

export default addressERC721TokensQuery