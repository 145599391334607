import React, { useState } from "react";

type Props = {
    tabs: string[];

}

const Tabs: React.FC<Props> = ({ tabs }) => {
    const [tabIndex, setTabIndex] = useState(0);

    return (
        <div className="tabs-wrapper">
            { tabs.map((tab, index) =>
                <div 
                    className={`tab-item ${tabIndex === index ? 'active' : ''}`}
                    key={`tab-item-${index}`}
                    onClick={() => setTabIndex(index)}
                >
                    {tab}
                </div>
            )}
        </div>
    )
};

export default Tabs;