import React, { useDebugValue, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Container, Card, CardBody, CardTitle, UncontrolledAlert, CardHeader } from "reactstrap";
import TransactionsFragment from "../../components/Monsta/transactionsFragment";


//import Breadcrumbs
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TokenPriceFragment from "src/components/Monsta/tokenPriceFragment";
import SpinnerCustom from "src/components/Common/spinnerCustom";
import TokenPriceFragmentSTT from "src/components/Monsta/tokenPriceFragmentSTT";
import BlockWidgetFragment from "src/components/Monsta/blockWidgetFragment";



const Home = () => {

  const [ t, i18n ] = useTranslation();
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Monsta Scan</title>
        </MetaTags>
        <Container fluid>
            <Col>
               
                <Row>
                    <Col xl={4} md={6} sm={12}>
                        <Card>
                          <CardBody >
                            
                            <h5 className="mb-3 monsta-white-color">{t("Price Information")}</h5>
                            <TokenPriceFragment/>
                          
                          </CardBody>
                        </Card>
                    </Col>
                    
                    <Col xl={4} md={6} sm={12}>
                        <Card>
                        <CardBody >
                            
                            <h5 className="mb-3 monsta-white-color">{t("Price Information")}</h5>
                            <TokenPriceFragmentSTT/>
                          
                          </CardBody>
                        </Card>
                    </Col>
                    <Col xl={4} md={6} sm={12}>
                          <Card>
                            <CardBody >
                              <h5 className="mb-3"> {t("Latest Block")}</h5>
                              <BlockWidgetFragment/>
                            </CardBody>
                        </Card>
                    </Col>
                    
                </Row>
                <Row>
                  <Col xl="12">
                    <TransactionsFragment first={process.env.REACT_APP_LAST_TRANSACTIONS}/>
                  </Col>
                </Row>
            </Col>
      
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Home;
