import React from "react"
import { copyText } from "src/utils/utils"

type Props = {
    txt:any
    display:boolean
}


const CopyTextFragment = (props:Props) =>{

    return(
        <React.Fragment>
                {   
                    props.display && (
                        <span className="copy-address" onClick={() => {copyText(props.txt)}}><i className="far fa-copy"></i></span>
                    )
                }
        </React.Fragment>
    )
}

export default CopyTextFragment