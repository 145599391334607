import React from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Alert } from "reactstrap";
import { useParams } from "react-router";
import TransactionDetailFragment from "src/components/Monsta/transactionDetailFragment";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import specificTransactionsQuery from "src/graphql/specificTransactionQuery";
import SpinnerCustom from "src/components/Common/spinnerCustom";
import ErrorCustom from "src/components/Common/errorCustom";
import UnknownProblem from "src/components/Common/unknownProblem";
import { timeAgoFormat, timeConverter, timeUTCConverter } from "src/utils/utils";
import CopyTextFragment from "src/components/Monsta/copyTextFragment";
import Tabs from "src/components/Tabs";
import NonMatchingTransaction from "src/components/Monsta/nonMatchingTransaction";


type TransProps ={
    id:string
}

const TransactionDetail = () =>{
    const [ t, i18n ] = useTranslation();
    const { id }= useParams<TransProps>();

    //GET TOP DETAILS FROM TRANSACTION
    const { loading: txLoad, error: txError, data: txData } = useQuery(
        specificTransactionsQuery,
        {
            variables:{id:id}
        }
    );
   

    if (txLoad) return (<SpinnerCustom/>);
    if (txError) return (<ErrorCustom description={txError.message}/>);
    if (txData.transaction != null) {
        //console.log(txData.transaction.i)
        return(
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Transactions</title>
                    </MetaTags>
                    <div className="container-fluid ">
                    <Row className="p-4 pb-1">
                        <Col>
                            <span className="title">{t('Transaction Details')}</span>
                        </Col>
                    </Row>
                    <Row className="p-4 pb-1">
                        <Col>
                            <Tabs tabs={['Overview']} />
                        </Col>
                    </Row>
                    <Row className="p-4">
                        <Col>
                            <TransactionDetailFragment txData={txData} />
                        </Col>
                    </Row>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    return(
       <NonMatchingTransaction />
    )
}

export default TransactionDetail