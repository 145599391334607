import React, { useState } from "react"
import { useTranslation } from "react-i18next";
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import AddressERC20TransfersFragment from "./addressERC20TransfersFragment";
import { ERC20Transfer } from "src/models/erc20Transfer";
import { ERC721Transfer } from "src/models/erc721Transfer";
import { TransferTypes } from "src/models/transferTypes";
import AddressERC721TransfersFragment from "./addressERC721TransfersFragment";


type WrapperProps = {
    outgoingTransfers:ERC20Transfer[] | ERC721Transfer[]
    incomingTransfers:ERC20Transfer[] | ERC721Transfer[]
    type:TransferTypes
    id:any
}

const TransactionsTabsWrapperFragment = (props:WrapperProps) =>{
    const [ t, i18n ] = useTranslation();
    const [activeTab, setactiveTab] = useState("1");
    
    const toggleTabTransfer = (tab: any) => {
        if (activeTab !== tab) {
          setactiveTab(tab);
        }
    };
    
    //console.log(props.type)

    return(
        <React.Fragment>
           <Row className={"mt-2 p-2"}>

                <Col className={""}>
                    <div >
                        <div className="address-sub-tab">
                            <div>
                                <Nav pills className="nav-tabs-custom">
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                            active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                toggleTabTransfer("1");
                                            }}
                                        >
                                        {t("Outgoing Transfers")} 
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                            active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                toggleTabTransfer("2");
                                            }}
                                        >
                                        {t("Incoming Transfers")} 
                                        </NavLink>
                                    </NavItem>

                                </Nav>
                            </div>
                            <div >
                                <h5>{t("Latest Transfers", {tx:process.env.REACT_APP_LAST_TRANSFERS})} </h5>
                            </div>
                        </div>

                    </div>

                        <TabContent activeTab={activeTab} className="mt-4">
                            <TabPane tabId="1">
                            <Row>
                                <Col sm="12">                  
                                    {props.type == TransferTypes.ERC20 && <AddressERC20TransfersFragment erc20Transfers={props.outgoingTransfers} address={props.id}/>}
                                    {props.type == TransferTypes.ERC721 && <AddressERC721TransfersFragment erc721Transfers={props.outgoingTransfers} address={props.id}/> }  
                                </Col>
                            </Row>
                            </TabPane>
                            <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                {props.type == TransferTypes.ERC20 && <AddressERC20TransfersFragment erc20Transfers={props.incomingTransfers} address={props.id}/> }
                                {props.type == TransferTypes.ERC721 && <AddressERC721TransfersFragment erc721Transfers={props.incomingTransfers} address={props.id}/> }
                                </Col>
                            </Row>
                            </TabPane>
                        
                        </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    )

}

export default TransactionsTabsWrapperFragment