import {gql} from "@apollo/client"

const transactionsQuery = gql`
query transactionsQuery($first:Int!, $skip:Int!) {
  transactions(
    skip:$skip
    first:$first
    orderDirection:desc
    orderBy:blockNumber
  ){
    id
    timestamp
    blockNumber
  }
}
`;

export default transactionsQuery