import React from "react"
import MetaTags from "react-meta-tags";
import { Row, Col, Alert } from "reactstrap";
import { useTranslation } from "react-i18next";


const NonMatchingTransaction = ()=>{

    const [ t, i18n ] = useTranslation();
    return(
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Transactions</title>
                </MetaTags>
                <div className="container-fluid">
                <Row>
                    <Col xl={12} md={12} sm={12}>
                   
                    <Row className="custom-card-light-primary-alert p-4 mb-2" >
                        <Col>
                        <Row>
                            <Col xl={12} md={12} sm={12}>
                            <h3>{t("Transaction Details")}</h3>
                            </Col>
                        </Row>
                        <Row>
                        <Col xl={2} md={2} sm={3}>
                            {t("Transaction Hash")} -
                        </Col>
                        <Col xl={10} md={10} sm={9}>
                           
                        </Col>
                        </Row>
                        <Row>
                        <Col xl={2} md={2} sm={3}>
                                {t("Block")} -
                            </Col>
                            <Col xl={10} md={10} sm={9}>
                            
                            </Col>
                        </Row>
                        <Row>
                        <Col xl={2} md={2} sm={3}>
                                {t("Timestamp")} -
                            </Col>
                            <Col xl={10} md={10} sm={9}>
                            
                            </Col>
                        </Row>
                        </Col>
                    </Row>
                     <Row>
                        <Col className={"mt-4"}>
                        <Alert color="warning">
                           {t("There are no matching entries")}
                        </Alert>
                        </Col>
                    </Row>
                    </Col>
                </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NonMatchingTransaction