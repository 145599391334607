import React, { useEffect, useState } from "react";
import {Row,Col} from "reactstrap"
import { useTranslation } from 'react-i18next';
import { useQuery } from "@apollo/client";
import transactionDetailQuery from "../../graphql/transactionDetailQuery";
import SpinnerCustom from "../Common/spinnerCustom";
import ErrorCustom from "../Common/errorCustom";
import { ERC20Transfer } from "src/models/erc20Transfer";
import { Rectangle } from "leaflet";
import { Link, useHistory } from "react-router-dom";
import { formatAmount, searchContractName, zeroXToMoni } from "src/utils/utils";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import CopyTextFragment from "./copyTextFragment";
import { timeAgoFormat, timeConverter, timeUTCConverter } from "src/utils/utils";
import moniSvg from '../../assets/images/moni.png';
import sttSvg from '../../assets/images/stt.svg';
import InputDataDecoder, { InputData } from 'ethereum-input-data-decoder'
import ERC20ABI from '../../abi/ERC20.json'
import xMONIBridgeABI from '../../abi/xMONIBridge.json'
import xSTTBridgeABI from '../../abi/XSTTBridge.json'


type Props ={
    txData:any
}

const TransactionDetailFragment = (props:Props) => {

    /**
     * This vars allow dinamically load an eval the string in the 'abi property' 
     * to decode properly the transaction input
     * Do not delete
     */
    let xSTTBridge:any = xSTTBridgeABI;
    let xMONIBridge:any = xMONIBridgeABI;

    const history = useHistory()

    const {stateContracts} = useSelector((state:RootState) =>({
        stateContracts: state.ContractsReducer.contracts
      }))
    
    const [fullShowArray, setFullShowArray] = useState<any>();
    
    const [ t, i18n ] = useTranslation();

    //QUERY TO GET DETAILS FROM A TRANSACTION
    const { loading: detailLoad, error: detailError, data: detailData } = useQuery(
        transactionDetailQuery,
        {
            variables:{id:props.txData.transaction.id}
        }
    );
    const [erc20Transfers, setErc20Transfers] = useState<ERC20Transfer[]>([]);

    useEffect(() =>{
        if (detailData){
            //console.log(detailData)
            if (detailData.erc20Transfers.length==0){
                history.push('/transaction-erc721/'+props.txData.transaction.id)
            }else{
                setErc20Transfers(detailData.erc20Transfers)

                //create boolean arrays
                let boolArray: boolean[]= Array(detailData.erc20Transfers.length).fill(false)
                setFullShowArray(boolArray)
                
            }
        }
    },[detailData])

    const handleFullShowChange = (index:number) =>{
        //console.log(index)
        let vals = fullShowArray
        vals[index]= !fullShowArray[index]
        setFullShowArray(vals)
        //console.log(fullShowArray[index])
    } 

    const decodeERC20Input = (input:string, abi:any):InputData => {

        let decoder = new InputDataDecoder(eval(abi) as any)
        let result = decoder.decodeData(input)
        //console.log(result)
        return result
    }

    if (detailLoad) return (<SpinnerCustom/>);
    if (detailError) return (<ErrorCustom description={detailError.message}/>);

    const renderTransfers = () => {

        let transfers = erc20Transfers.map((transfer,index) =>{

            let fromAddress 
            if (transfer.from?.id != null){
                let searchFrom =searchContractName(transfer.from?.id, stateContracts);
                fromAddress= searchFrom != undefined ? searchFrom.name : zeroXToMoni(transfer.from?.id);
            }
            else{
                fromAddress = String(transfer.contract?.symbol).localeCompare("STT") == 0 ? "Minted": "Monsta Bridge";
            }

            let toAddress
            if (transfer.to?.id != null){
                let searchTo = searchContractName(transfer.to?.id,stateContracts);
                toAddress =  searchTo != undefined ? searchTo.name : zeroXToMoni(transfer.to?.id);
            }else{
                toAddress = String(transfer.contract?.symbol).localeCompare("STT") == 0 ? "Burned": "Monsta Bridge";
            }

            let displayLinkAndCopyFrom:boolean = transfer.from?.id != null ? true :false
            let displayLinkAndCopyTo:boolean = transfer.to?.id != null ? true :false


            let symbolReplace = String(transfer.contract?.symbol).localeCompare("STT") ==0 ?"xSTT": transfer.contract?.symbol


            /**
             * Load the ABI to decode the transaction input
             */

            let decodedInput:InputData
            let decodedFrom = searchContractName(transfer.from?.id, stateContracts)
            let decodedTo =searchContractName(transfer.to?.id,stateContracts) 

            if (decodedFrom != undefined && decodedFrom.abi != undefined){
                //console.log("From:" + decodedFrom.abi)
                decodedInput= decodeERC20Input(transfer.transaction?.input!, decodedFrom.abi)
            }
            else if (decodedTo != undefined && decodedTo.abi != undefined){
                //console.log("To:" + decodedTo.abi)
                decodedInput= decodeERC20Input(transfer.transaction?.input!, decodedTo.abi)
            }
            else{
                decodedInput= decodeERC20Input(transfer.transaction?.input!, ERC20ABI)
            }

            
            return(
                <Row key={"ContTx_" + index} className={"monsta_transactions_detail"}>
                    <Col >
                        <Row key={"hash_" + index} >
                            <Col xl={2} md={2} sm={3}>
                                {t("Transaction Hash")}
                            </Col>
                            <Col xl={10} md={10} sm={9} className="d-flex">
                                <span className="text-ellipsis">
                                    {props.txData.transaction.id}
                                </span>
                                <CopyTextFragment txt={props.txData.transaction.id} display={displayLinkAndCopyFrom}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={2} md={2} sm={3}>
                                {t("Block")}
                            </Col>
                            <Col xl={10} md={10} sm={9}>
                                {props.txData.transaction.blockNumber}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={2} md={2} sm={3}>
                                {t("Timestamp")}
                            </Col>
                            <Col xl={10} md={10} sm={9}>
                               {` ${timeAgoFormat(props.txData.transaction.timestamp)} (${timeUTCConverter(props.txData.transaction.timestamp)})`}
                                
                            </Col>
                        </Row>
                        <Row key={"from_" + index} >
                            <Col xl={2} md={2} sm={3}>
                                {t("From")}
                            </Col>
                            <Col xl={10} md={10} sm={9} className="d-flex">
                                <span className="text-ellipsis">
                                {displayLinkAndCopyFrom ? (
                                <Link to={`/address/${zeroXToMoni(transfer.from?.id)}`} className="monsta-link"> {fromAddress} </Link>
                                ):(fromAddress)
                                    }
                                </span>
                                <CopyTextFragment txt={zeroXToMoni(transfer.from?.id)} display={displayLinkAndCopyFrom}/>
                            </Col>
                        </Row>
                        <Row key={"To_" + index}>
                            <Col xl={2} md={2} sm={3}>
                                {t("To")}
                            </Col>
                            <Col xl={10} md={10} sm={9} className="d-flex">
                            <span className="text-ellipsis">
                                    {displayLinkAndCopyTo ? (
                                <Link to={`/address/${zeroXToMoni(transfer.to?.id)}`} className="monsta-link"> {toAddress}</Link>
                                ):(toAddress)}
                                </span>
                            <CopyTextFragment txt={zeroXToMoni(transfer.to?.id)} display={displayLinkAndCopyTo} />
                            </Col>
                        </Row>

                        <Row key={"Value_" + index}>
                            <Col xl={2} md={2} sm={3}>
                                {t("Value")}
                            </Col>
                            <Col xl={10} md={10} sm={9} className="value-row">
                                {formatAmount(transfer.value, 4)}
                                {symbolReplace.toLowerCase() === 'xmoni' && <img src={moniSvg} alt="moni" />}
                                {symbolReplace.toLowerCase() === 'xstt' && <img src={sttSvg} alt="stt" />}
                                {`${transfer.contract?.name} (${symbolReplace})`}
                            </Col>
                        </Row>
                        <Row key={"Sep_" + index}>
                             <Col xl={12}><hr/></Col>
                        </Row>
                       <Row>
                            <a className="btn-more" href="#!" onClick={()=> {handleFullShowChange(index)}}>
                                {fullShowArray[index] ? 'Click to see less' : 'Click to see more'} <i className={`fas ${fullShowArray[index] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                            </a>
                        </Row>
                     
                        {fullShowArray[index] && (
                        <Row>
                            <Row key={"GasLimit_" + index}>
                                <Col xl={2} md={2} sm={3}>
                                    {t("Gas Limit")}
                                </Col>
                                <Col xl={10} md={10} sm={9} className="value-row">
                                    
                                    {transfer.transaction?.gasLimit != null ? transfer.transaction?.gasLimit : t("Non available")}
                                </Col>
                            </Row>
                       
                            <Row key={"GasPrice_" + index}>
                                <Col xl={2} md={2} sm={3}>
                                    {t("Gas Price")}
                                </Col>
                                <Col xl={10} md={10} sm={9} className="value-row">
                                    
                                    {transfer.transaction?.gasPrice != null ? transfer.transaction?.gasPrice : t("Non available")}
                                </Col>
                            </Row>

                            <Row key={"Nonce_" + index}>
                                <Col xl={2} md={2} sm={3}>
                                    {t("Nonce")}
                                </Col>
                                <Col xl={10} md={10} sm={9} className="value-row">
                                    
                                    {transfer.transaction?.nonce != null ? transfer.transaction?.nonce : t("Non available")}
                                </Col>
                            </Row>

                            <Row key={"Input_" + index}>
                                <Col xl={2} md={2} sm={3}>
                                    {t("Input")}
                                </Col>
                                <Col xl={10} md={10} sm={9} className="value-row">
                                    <div className="text-input-log">
                                        {transfer.transaction?.input}<br/><br/>
                                        {t("Decoded Info")}<br/>
                                        {t("Method") + decodedInput.method }<br/>
                                        {t("Types") + JSON.stringify(decodedInput.types)}<br/>
                                        {t("Inputs") + JSON.stringify(decodedInput.inputs)}<br/>
                                        {t("Names") + JSON.stringify(decodedInput.names)}
                                        
                                    </div>
                                   
                                </Col>
                            </Row>

                            <Row key={"LogIndex_" + index}>
                                <Col xl={2} md={2} sm={3}>
                                    {t("Log Index")}
                                </Col>
                                <Col xl={10} md={10} sm={9} className="value-row">
                                    
                                    {transfer.transaction?.logIndex != null ? transfer.transaction?.logIndex: t("Non available")}
                                </Col>
                            </Row>

                            <Row key={"TransactionLogIndex_" + index}>
                                <Col xl={2} md={2} sm={3}>
                                    {t("Transaction Log Index")}
                                </Col>
                                <Col xl={10} md={10} sm={9} className="value-row">
                                    
                                    {transfer.transaction?.transactionLogIndex != null ? transfer.transaction?.transactionLogIndex : t("Non available")}
                                </Col>
                            </Row>

                            <Row key={"LogType_" + index}>
                                <Col xl={2} md={2} sm={3}>
                                    {t("Log Type")}
                                </Col>
                                <Col xl={10} md={10} sm={9} className="value-row">
                                    
                                    {transfer.transaction?.logType != null ? transfer.transaction.logType : t("Non available")}
                                </Col>
                            </Row>
                          
                        </Row>)
                        }
                    </Col>
                </Row>
            )
        })

        return transfers

    }
 
  if (erc20Transfers.length>0){
        return(
            <React.Fragment>
                {
                   renderTransfers()
                }
            </React.Fragment>
        )
    }
    return(
        <React.Fragment>
            <Row>
                <Col>
                </Col>
            </Row>
        </React.Fragment>
    )

}

 
export default TransactionDetailFragment;