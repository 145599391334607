import React, {useState} from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

type Props = {
    options: number[];
    size: number;
    onChange: (n: number) => void;
};

const SizePerPageDropdown: React.FC<Props> = ({options, size, onChange}) => {
    const [open, setOpen] = useState(false);

    return (
        <Dropdown className="size-page-dropdown" isOpen={open} toggle={() => setOpen(!open)}>
        <DropdownToggle caret={true}>
            {size}
        </DropdownToggle>
        <DropdownMenu>
            {options.map(opt => 
                <DropdownItem key={opt} onClick={()=>onChange(opt)}>{opt}</DropdownItem>
            )}
        </DropdownMenu>
        </Dropdown>
    );
};

export default SizePerPageDropdown;