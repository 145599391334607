import {  Dispatch } from "redux";
export interface PricesState{
    price:any
    priceSTT:any
}

export const InitialPriceState : PricesState ={
    price: null,
    priceSTT:null
}

export enum PricesTypes{
    SET_MONI_PRICE='SET_MONI_PRICE',
    SET_STT_PRICE='SET_STT_PRICE'
}

export interface setPriceAction {
    type : PricesTypes.SET_MONI_PRICE,
    payload: any
}

export interface setPriceSTTAction{
    type:PricesTypes.SET_STT_PRICE,
    payload:any
}

export type priceActions = setPriceAction | setPriceSTTAction

export const setMoniPrice = (price:string) => {
    return {type : PricesTypes.SET_MONI_PRICE,
    payload: price
    }
}

export const setSTTPrice = (price:string) => {
    return {
        type: PricesTypes.SET_STT_PRICE,
        payload:price
    }
}

export const PriceReducer = (state:PricesState=InitialPriceState, action:priceActions) =>{

    switch(action.type){
        case PricesTypes.SET_MONI_PRICE :{
           return{ 
               ...state,
               price:action.payload
           }
        }
        case PricesTypes.SET_STT_PRICE :{
            return{
                ...state,
                priceSTT:action.payload
            }
        }
        default:
        return state
    }

}
