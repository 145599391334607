export const transactionColumns = [
  {
    dataField: 'df1',
    isDummyField: true,
    text: '',
    style: {
      color:"white",
      fontSize:"1rem",
      cursor:"pointer"
    },
    formatter: (cellContent:any, row:any) => {
    return (
          <div className="">
           <i className="fas fa-layer-group"> </i>
           </div>
    );
    }
  },
    {
      dataField: "id",
      text: "Transaction Hash",
      sort: false,
      style: {
        cursor:"pointer"
      }
    },
    {
      dataField: "timestamp",
      text: "Date",
      sort: false,
      style:{
        cursor:"pointer",
        color:"#FFF",
      }
    },
    {
      dataField: "blockNumber",
      text: "Block",
      sort: false,
      style:{
        cursor:"pointer"
      }
    }, 
  ];