import React, { useEffect, useState } from "react";
import {Row,Col} from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {useQuery} from "@apollo/client"
import BootstrapTable from "react-bootstrap-table-next";
import {useHistory} from 'react-router-dom';
import transactionsQuery from "src/graphql/transactionsQuery";
import {transactionColumns} from "../../models/transactionsColumns"
import { timeAgoFormat, timeConverter, timeUTCConverter } from "src/utils/utils";
import {Transaction} from "../../models/transaction"
import { useTranslation } from 'react-i18next';
import SpinnerCustom from "../Common/spinnerCustom";
import ErrorCustom from "../Common/errorCustom";
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import MonstaPagination from "../Pagination";
import SizePerPageDropdown from "../SizePerPageDropdown";
import { ERC20Transfer } from "src/models/erc20Transfer";


type TransactionsFragmentProps ={
  first:any
}


const TransactionsFragment = (props:TransactionsFragmentProps) => {
  const [page, setPage] = useState(1);
  const total = 1000;
  const [totalPages, setTotalPages] = useState(total/25);
  const [sizePerPage, setSizePerPage] = useState(25);

  useEffect(() => {
    setTotalPages(Math.floor(total/sizePerPage));
  }, [sizePerPage]);
  
  //GET LAST 100 TRANSACTIONS IN HOME
  const { loading, error, data } = useQuery(
    transactionsQuery,
    {
      variables:{
        first: sizePerPage,
        skip: (page-1)*sizePerPage
      }
    }
  );


  const history = useHistory()
  const [trx, setTrx]= useState<Transaction[]>([]);
  const [t, i18n] = useTranslation();


  // Select All Button operation
  const selectRow: any = {
    mode: 'checkbox',
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect:(row:any,isSelect:any,rowIndex:any,e:any)=>{
        //console.log(row.id)
        history.push('/transaction/'+row.id)
    }
  };

  const customTotal = (size: number) => (
    <span className="react-bootstrap-table-pagination-total">
      Show 1000 latest records
    </span>
  );

  useEffect(()=>{
    if (data){
      let ts:Transaction[]= [];

      /*let filteredTX: ERC20Transfer[]= data.erc20Transfers.filter((tx:ERC20Transfer,index:any,a:ERC20Transfer[]) =>{
        //console.log("TX:" + JSON.stringify(tx))
        if (index >0)  {
          return tx.transaction?.id !== a[index-1].transaction?.id 
        }
        else{
          return tx
        }
      }
      )*/

      //console.log("Filtered TX:" + JSON.stringify(filteredTX))

      data.transactions.map((tx:Transaction,index:any) =>{
        var d= `${timeAgoFormat(tx.timestamp)} (${timeUTCConverter(tx.timestamp)})`;
        //console.log(JSON.stringify(tx.block.number))
        let idData= tx.id + index
        let t:Transaction= {id:tx.id,blockNumber:tx.blockNumber,timestamp:d, idData:idData}
        ts.push(t)
        
      })
      setTrx(ts);
    }
  },[data])

  if (loading) return (<SpinnerCustom/>);
  if (error) return (<ErrorCustom description={error.message}/>);
  
  return(      
    <React.Fragment>
        <Row>
          <Col>
                <ToolkitProvider
                  keyField="idData"
                  columns={transactionColumns}
                  data={trx} 
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="d-flex justify-content-between mb-3">
                        <Col xl={6} className="d-flex align-items-center justify-content-start gap-5 table-control">
                          <h3>{t("Transactions")}</h3>
                          {customTotal(1000)}
                        </Col>
                        <Col xl={6} className="d-flex align-items-center justify-content-end gap-5 table-control">
                          <div className="d-flex align-items-center gap-2">
                            Show
                            <SizePerPageDropdown options={[10, 20, 25, 50, 100]} size={sizePerPage} onChange={(n)=> setSizePerPage(n)} />
                            Record
                          </div>
                          <MonstaPagination page={page} total={totalPages} onChangePage={(n) => setPage(n)} />
                        </Col>                            
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              // responsive
                              bordered={false}
                              striped={false}
                              selectRow={selectRow}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end mb-5">
                        <MonstaPagination page={page} total={totalPages} onChangePage={(n) => setPage(n)} />
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
          </Col>
        </Row>
    </React.Fragment>
  )
}



export default TransactionsFragment