import Axios, { AxiosRequestConfig, AxiosInstance } from 'axios'


export const geckoConnectionTimeout = 10000
export const geckoTimedOutError = "Connection Timeout Error"
const geckoEndpoint = process.env.REACT_APP_GECKO_API
const geckoResponseTimeout = 20000
const geckoBaseHeaders =  {'Content-Type': 'application/json;charset=utf-8'}

export const cmcConnectionTimeout = 10000
export const cmcTimedOutError = "Connection Timeout Error"
const cmcEndpoint = process.env.REACT_APP_CMC_SELF_API
const cmcResponseTimeout = 20000
const cmcBaseHeaders = {'Content-Type': 'application/json;charset=utf-8'}


export const geckoApplicationInstance =  Axios.create({
  baseURL: geckoEndpoint,
  timeout: geckoResponseTimeout,
  headers: geckoBaseHeaders
})

export const cmcApplicationInstance = Axios.create({
  baseURL: cmcEndpoint,
  timeout: cmcConnectionTimeout,
  headers: cmcBaseHeaders
})