import Axios from 'axios'
import { cmcPriceItem } from 'src/models/cmcAPI'
import { cmcApplicationInstance, cmcConnectionTimeout, geckoApplicationInstance, geckoConnectionTimeout } from '../axios/config'
import {GeckoPriceItem, GeckoPriceRequest} from '../models/geckoAPI'

export const Prices = {

 async getTokenPrice(contract:string, chain:string, currency:string):Promise<any> {

        let response:any = null
        const source = Axios.CancelToken.source()
        setTimeout(() => { if (response === null) { source.cancel() } }, geckoConnectionTimeout)

        const url = `${process.env.REACT_APP_GECKO_API}${process.env.REACT_APP_GECKO_API_PRICE_SEGMENT}${chain}`
        const p:GeckoPriceRequest = {contract_addresses:contract, vs_currencies:currency}

        return (response = await geckoApplicationInstance
        .get(url, {params:p, cancelToken:source.token})
        .then(response => {
            const price:GeckoPriceItem = response.data
            return Promise.resolve(price[contract].usd?.toString())
            
        })
        .catch(error => {
            return Promise.reject(error)
        }))
    },

async cmcGetTokenPrice(id:any):Promise<any>  {
    let response:any = null
    const source = Axios.CancelToken.source()
    setTimeout(() => { if (response === null) { source.cancel() } }, cmcConnectionTimeout)
    const url = `.netlify/functions/cmcpricefetch?`
    //const p = "slug=monsta-infinite"
    return (
        response = await cmcApplicationInstance
        .get(`${url}id=${id}`,{cancelToken:source.token})
        .then(response => {
            const priceObject:cmcPriceItem = response.data.data
            /*console.log(response)
            console.log(priceObject)
            console.log(priceObject[id].quote.USD.price)*/
            return Promise.resolve(priceObject[id].quote.USD.price?.toString())
        })
        .catch (error =>{
            return Promise.reject(error)
        })
    )
}

}