import { combineReducers } from "redux"

// Layout
import Layout from "./layout/reducer";

// User Profile 
import profile from "./auth/profile/reducer";

import  {PriceReducer}  from "./prices/reducer";

import { ContractsReducer } from "./contracts/reducer";


const rootReducer = combineReducers({
  // public
  Layout,
  profile,
  PriceReducer,
  ContractsReducer
})
export type RootState= ReturnType<typeof rootReducer>;
export default rootReducer
