import {gql} from "@apollo/client"

const addressERC721TransactionsQuery = gql`
   
query addressERC721TransactionsQuery($id:ID!, $first:Int!){
    account(id:$id){
        id
        ERC721tokens(
         skip:0
         first:$first
       ){
         id
         contract{
           name
           symbol
         }
       }
       
       ERC721transferFromEvent(
         skip:0
         first:$first
         orderBy:timestamp
          orderDirection:desc
       ){
         id
         timestamp
         transaction{
           id
           timestamp
           blockNumber
         }
         contract{
           name
           symbol
           id
         }
         token{
           id
             identifier
           owner{
             id
           }
         }
         from{
           id
         }
         to{
           id
         }
         
       }
       
        ERC721transferToEvent(
         skip:0
         first:$first
         orderBy:timestamp
          orderDirection:desc
       ){
         id
         timestamp
         transaction{
           id
           timestamp
           blockNumber
         }
         contract{
           name
           symbol
           id
         }
         token{
           id
             identifier
           owner{
             id
           }
         }
         from{
           id
         }
         to{
           id
         }
         
       }
       
     }
  }
`;

export default addressERC721TransactionsQuery