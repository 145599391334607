import React, { useState } from "react";
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

import { Link } from "react-router-dom";

// Redux Store
import { toggleLeftmenu } from "../../store/actions";
// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import RightSidebar from "../CommonForBoth/RightSidebar";
import LightDark from "../CommonForBoth/Menus/LightDark";

// import images
import logo from "../../assets/images/monsta-logo.png";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import SearchFragment from "../Monsta/searchFragment";

const Header = (props: any) => {
  const dispatch = useDispatch();
  const { leftMenu, layoutMode } = useSelector((state: any) => ({
    leftMenu: state.Layout.leftMenu,
    layoutMode: state.Layout.layoutMode,
  }));

  const [isSearch, setSearch] = useState<boolean>(false);
  const [socialDrp, setsocialDrp] = useState<boolean>(false);
  const [position, setPosition] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);

  const toggleTopDrawer = () => {
    setPosition("right");
    setOpen(!open);
  };

  const onDrawerClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/transactions" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="48" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="48" />
                  <span className="logo-txt">Monsta Scan</span>
                </span>
              </Link>

              <Link to="/transactions" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="48" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="48" />
                  <span className="logo-txt">Monsta Scan</span>
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                dispatch(toggleLeftmenu(!leftMenu));
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

          </div>

          <div className="d-flex col-lg-8 col-md-8 justify-content-end">
            
            <div className="desktop flex-fill">
              <SearchFragment />
            </div>
            <LanguageDropdown />
            <NotificationDropdown />

            {/* light / dark mode */}
            <LightDark
              layoutMode={layoutMode}
              onChangeLayoutMode={props.onChangeLayoutMode}
            />

           

            
            
            <div className="dropdown d-inline-block">
              <button
                onClick={toggleTopDrawer}
                disabled={open}
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <Icon name="settings" className="icon-lg" />
              </button>
            </div>
         
            <ProfileMenu />
          </div>
        </div>
        <div className="row">
          <div className="d-flex col-lg-4 mobile" >
            <SearchFragment />
          </div>
        </div>
      </header>
      <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
        <RightSidebar
          onClose={onDrawerClose}
          onChangeLayoutMode={props.onChangeLayoutMode}
        />
      </ReactDrawer>
    </React.Fragment>
  );
};

export default Header;
