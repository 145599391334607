import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { ERC20Transfer } from "src/models/erc20Transfer";
import { RootState } from "src/store/reducers";
import { ellipsify, formatAmount, searchContractName, timeAgoFormat, timeUTCConverter, zeroXToMoni } from "src/utils/utils";

import moniSvg from '../../assets/images/moni.png';
import sttSvg from '../../assets/images/stt.svg';

type TransfersProps = {
    erc20Transfers:ERC20Transfer[]
    address:string
}

const AddressERC20TransfersFragment = (props:TransfersProps) =>{
  const history = useHistory()
  const [transfers,setTransfers] = useState<ERC20Transfer[]>([]);
  const [clickTransfer, setClickTransfers]= useState<ERC20Transfer[]>([]);

  const {stateContracts} = useSelector((state:RootState) =>({
    stateContracts: state.ContractsReducer.contracts
  }))

  useEffect(()=>{

    let ts:ERC20Transfer[]=[];
    let clickts: ERC20Transfer[] =[];
    props.erc20Transfers.map((transfer,index) =>{
      
      /**Change addresses to Human readable */
      let from
      if (transfer.from?.id != null){
        let searchFrom = searchContractName(transfer.from?.id, stateContracts)
        from = searchFrom != undefined ? searchFrom.name : ellipsify(zeroXToMoni(transfer.from?.id),18,5)
      }else {
        from= String(transfer.contract?.symbol).localeCompare("STT") == 0 ? "Minted": "Monsta Bridge";
      }

      let to
      if (transfer.to?.id != null){
        let searchTo = searchContractName(transfer.to?.id, stateContracts)
        to= searchTo != undefined ? searchTo.name : ellipsify(zeroXToMoni(transfer.to?.id),18,5)
      }else{
        to= String(transfer.contract?.symbol).localeCompare("STT") == 0 ? "Burned": "Monsta Bridge";
      }

      let symbolReplace = String(transfer.contract?.symbol).localeCompare("STT") ==0 ?"xSTT": transfer.contract?.symbol
        
      
       let idData= transfer.id + index;
       //console.log(idData)
       let d= `${timeAgoFormat(transfer.timestamp)} (${timeUTCConverter(transfer.timestamp)})`;
       let val = formatAmount(transfer.value,4)
       let tr:ERC20Transfer ={id:ellipsify(transfer.transaction?.id,10,6) ,from:from,to:to,contract:symbolReplace,value:val,timestamp:d, idData:idData}
       let clicktr: ERC20Transfer= {id:transfer.transaction?.id, from:zeroXToMoni(transfer.from?.id), to:zeroXToMoni(transfer.to?.id)}
       ts.push(tr)
       clickts.push(clicktr)
   })
    setTransfers(ts)
    setClickTransfers(clickts)
 },[props.erc20Transfers])

 const selectRow = (type:string, id:any) => {
      switch (type){
        case "hash":
          history.push('/transaction/'+id)
        break
        case "address":
          history.push('/address/'+id)
        break
      }
      

};

const pageOptions: any = {
  custom: true,
  paginationSize: 1,
  showTotal: true,
  totalSize: props.erc20Transfers.length, 
  sizePerPage: process.env.REACT_APP_RESULTS_PP_TRANSFERS,
  firstPageText: "First",
  lastPageText: 'Last',
  alwaysShowAllBtns : true,
};



  const transferColumns = [

    {
      dataField: 'idData',
      isDummyField: true,
      text: '',
      style: {
        textDecoration:"underline",
        color:"white",
        fontSize:"1rem"
      },
      formatter: (cellContent:any, row:any) => {
      return (
            <div className="">
             <i className="fas fa-layer-group"> </i>
             </div>
      );
      }
    },
    {
      dataField: "id",
      text: "Transaction Hash",
      sort: false,
      style: {
        textDecoration:"underline",
        cursor:"pointer"
      },
      events: {
        onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
          if(clickTransfer[rowIndex].id != undefined && clickTransfer[rowIndex].id != null && clickTransfer[rowIndex].id != ""){
            selectRow("hash", clickTransfer[rowIndex].id)
          }
          
        }
      }
    },
    {
      dataField: "from",
      text: "From:",
      sort: false,
      style: (cell:any,row:any,rowIndex:any,colIndex:any) =>{
          if (String(row.from).localeCompare("Monsta Bridge") ==0 || String(row.from).localeCompare("Minted")==0 || String(clickTransfer[rowIndex].from).localeCompare(props.address) == 0){
            return{
              textDecoration:"none",
              cursor:"auto",
              color:"white"
            }
          }
          
          return{
            textDecoration:"underline",
            cursor:"pointer"
          }
        }
      ,
      events: {
        onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
          //console.log(clickTransfer[rowIndex].from)
          if(clickTransfer[rowIndex].from != undefined && clickTransfer[rowIndex].from != null && clickTransfer[rowIndex].from != "" && String(clickTransfer[rowIndex].from).localeCompare(props.address) != 0){
            selectRow("address", clickTransfer[rowIndex].from)
          }
        }
      }
    },
    {
      dataField: "to",
      text: "To:",
      sort: false,
      style: (cell:any,row:any,rowIndex:any,colIndex:any) =>{
        if (String(row.to).localeCompare("Monsta Bridge") ==0 || String(row.to).localeCompare("Burned")==0 || String(clickTransfer[rowIndex].to).localeCompare(props.address) == 0) {
          return{
            textDecoration:"none",
            cursor:"auto",
            color:"white"
          }
        }
        
        return{
          textDecoration:"underline",
          cursor:"pointer"
        }
      },
      events: {
        onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
          //console.log(clickTransfer[rowIndex].to)
          if(clickTransfer[rowIndex].to != undefined && clickTransfer[rowIndex].to != null && clickTransfer[rowIndex].to != "" && String(clickTransfer[rowIndex].to).localeCompare(props.address) != 0){
            selectRow("address", clickTransfer[rowIndex].to)
          }
        }
      }
    },
    {
        dataField: "contract",
        text: "Token:",
        sort: false,
        style:{
          color:"#FFF"
        },
        formatter: (cellContent:any, row:any) => {
          return (
            <div className="flex-row">
              {cellContent.toLowerCase() === 'xmoni' && <img src={moniSvg} alt="moni" />}
              {cellContent.toLowerCase() === 'xstt' && <img src={sttSvg} alt="stt" />}
              {cellContent}
            </div>
          );
      },
    },
    {
        dataField: "value",
        text: "Value:",
        sort: false,
        style:{
          color:"#FFF"
        }
      },
      {
        dataField: "timestamp",
        text: "Timestamp",
        sort: false,
        style:{
          color:"#FFF"
        }
      },
  ];
  


    return(
        <React.Fragment>
     
        <Row>
            <Col>
            <PaginationProvider
                    pagination={paginationFactory(pageOptions)}

                  >
                    {({ paginationProps, paginationTableProps }) => (
           
            <ToolkitProvider
                    keyField="idData"
                    columns={transferColumns}
                    data={transfers}
                    
                  >
                {toolkitProps =>(
                  <React.Fragment>
                     <Row>
                     <Col xl="12">
                       <div className="table-responsive">
                         <BootstrapTable
                           // responsive
                           bordered={false}
                           striped={false}
                           classes={"table align-middle table-nowrap"}
                           headerWrapperClasses={"thead-light"}
                           {...toolkitProps.baseProps}
                           {...paginationTableProps}
                         />
                       </div>
                     </Col>
                   </Row>
                   <Row className="align-items-md-center mt-30">
                       <Col className="inner-custom-pagination d-flex">
                         
                         <div className="text-md-right ms-auto">
                           <PaginationListStandalone
                             {...paginationProps}
                           />
                         </div>
                       </Col>
                     </Row>
                     </React.Fragment>
                )

                }
            </ToolkitProvider>
            )}
             </PaginationProvider>
            
            </Col>
        </Row>
    </React.Fragment>
    )
}

export default AddressERC20TransfersFragment