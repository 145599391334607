import { useQuery } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import blocksQuery from "src/graphql/blocksQuery"
import { Block } from "src/models/block"
import { ellipsify, timeAgoFormat, timeUTCConverter } from "src/utils/utils"
import { useTranslation } from 'react-i18next';

const BlockWidgetFragment = () =>{


    const [t, i18n] = useTranslation();

    const {loading, error, data } =useQuery(
        blocksQuery,{
            variables:{
                first:1,
                skip:0
            }
        }
    )

    const [block, setBlock] = useState<Block>()

    //If data exists 
    useEffect(()=>{
        if (data){
            //console.log(data)
            let blocks:Block[]=[]
            data.blocks.map((block:Block,index:any) =>{
                var d= `${timeAgoFormat(block.timestamp)} (${timeUTCConverter(block.timestamp)})`;
                let eHash = ellipsify(block.id,25,25)
                let b: Block= {
                    id: eHash,      
                    number: block.number,
                    timestamp: d,
                    gasUsed: block.gasUsed,
                    gasLimit: block.gasLimit,
                    difficulty: block.difficulty,
                    size: block.size
                }
                
                blocks.push(b)
            })

            setBlock(blocks[0])
        }
    },[data]);
    
    return (
        <React.Fragment>
            <Col>
                <Row>
                  
                    <p className="card-text block-information">
                        {`${t("Date")}  ${block?.timestamp}`} <br/>
                        {`${t("Number")}  ${block?.number}`} <br/>                 
                    </p>
        
                </Row>
            </Col>
        </React.Fragment>
    )
}

export default BlockWidgetFragment