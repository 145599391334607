import {gql} from "@apollo/client"

const transactionERC721DetailsQuery = gql`
query transactionERC721DetailQuery($id:ID!) {
    erc721Transfers(
      first:20
      skip:0
      orderBy:id
      orderDirection:asc
      where: {transaction:$id}
      ) {
        id
        token{
            id
            identifier
            approval

        }
        contract {
          symbol
          name
        }
        from {
          id
        }
        to {
          id
        }
        timestamp
        block{
          id
          hash
          number
          gasUsed
          gasLimit
          timestamp
          difficulty
          size
        }
    		transaction{
          id
          blockNumber
          gasLimit
          gasPrice
          nonce
          input
          logIndex
          transactionLogIndex
          logType
        }
      }
  }
`;

export default transactionERC721DetailsQuery