import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { Col, Container, Row, UncontrolledAlert } from "reactstrap"

const SearchFragment = () =>{

    const [searchValue, setSearchValue] =useState("")
    const history = useHistory()
    const [showErrorSearchValue, setShowErrorSearchValue] = useState(false)
    const onDismiss = () => setShowErrorSearchValue(false);
    const [ t, i18n ] = useTranslation();

    useEffect(()=>{
      window.setTimeout(()=>{
        setShowErrorSearchValue(false)
      },8000)

    },[showErrorSearchValue])

    const enterSearch = () =>{

        let searchVal = searchValue.trim()

        switch(searchVal.length){
          case 47:
            history.push('/address/'+searchVal.toLocaleLowerCase())
          break
          case 66:
            history.push('/transaction/'+searchVal.toLocaleLowerCase())
          break
          default:
            setShowErrorSearchValue(true)
          break
        }
      }
    
    const validateKeyPress = (e:any)=>{
      if (e.key ==="Enter"){
        enterSearch()
      }
    }

    return (
        <React.Fragment>
            <Container fluid>
            <Row>
                <Col>
                <div className="app-search d-lg-block">
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("Search by TX or Address")}
                      onChange={(v)=>{setSearchValue(v.target.value)}}
                      onKeyDown={e => {validateKeyPress(e)}}
                    />
                    <button className="btn btn-primary" type="button" onClick={()=>enterSearch()}>
                      <i className="bx bx-search-alt align-middle"></i>
                    </button>
                  </div>
                </div>
                </Col>
                
            </Row>
            <Row>
                <UncontrolledAlert style={{"position":"absolute","width":"500px","right":"10px","top":"10px"}} fade={true} color="danger" className="alert-label-icon label-arrow" isOpen={showErrorSearchValue} toggle={onDismiss}>
                  <i className="mdi mdi-block-helper label-icon"></i>
                    {t("It seems that you entered a non valid Tx hash or Address")}
                </UncontrolledAlert>
             </Row>
            </Container>
        </React.Fragment>
    )

}

export default SearchFragment